import React, { useState, useEffect, useCallback } from 'react';
import { Container } from 'reactstrap';
import "yet-another-react-lightbox/styles.css";
import TagList from './TagList';
import BounceLoader from 'react-spinners/BounceLoader'
import { useNavigate, useParams } from "react-router-dom";
import './Photo.css';

function Gallery() {
    const [photo, setPhoto] = useState([]);
    const [allTags, setAllTags] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    let { photoid } = useParams();
    let navigate = useNavigate();


    const fetchPhoto = async (photoId) => {
       
        let url = `api/photo/${photoId}`;

        const response = await fetch(url);
        const photo = await response.json();

        return photo;
    };

    const fetchTags = useCallback(async () => {
        const response = await fetch('api/tag');
        const fetchedTags = await response.json();

        return fetchedTags;
    }, []);

    useEffect(() => {
        setError(null);

        const initialize = async () => {
            try {
                const [fetchedPhoto, fetchedTags] = await Promise.all([fetchPhoto(photoid), fetchTags()]);

                setPhoto(fetchedPhoto);
                setAllTags(fetchedTags);
            }
            catch (ex) {
                setError(ex);
            }
            finally {
                setLoading(false);
            }
        }
        initialize();

    }, []);

    const toggleTag = async (tag) => {

        navigate(`/gallery/${tag.name}`, { replace: false });
    };
    
    let loader = <p className="photo-loader"><BounceLoader color="#36d7b7" /></p>;
    let errorAlert = <p className="photo-noresults">Could not load the photo. Sorry!</p>
    let nothingToShow = <p className="photo-noresults">Photo does not exist</p>

    let mainContent;
    if (loading)
        mainContent = loader;
    else if (error != null)
        mainContent = errorAlert;
    else if (photo != null) 
        mainContent = <img src={photo.url} className='main-photo'></img>;
    else
        mainContent = nothingToShow;
    let tagFilter = <TagList key="tag-list" tags={allTags} onTagToggle={toggleTag} />;

    return (
        <Container fluid id="photo-viewer-container" className="">
            {tagFilter}

            <div className="row">

                <div className="photo-viewer col">
                    {mainContent}
                </div>
            </div>
        </Container>
    );
}

export default Gallery;