import Gallery from "./components/Gallery";
import Photo from "./components/Photo";
import Author from "./components/Author";
import NotFound from "./error/NotFound";

const AppRoutes = [
    {
        path: '*',
        element: <NotFound />
    },
    {
        index: true,
        element: <Gallery />
    },
    {
        path: '/gallery/:tagsCsv?',
        element: <Gallery />
    },
    {
        path: '/author',
        element: <Author />
    },
    {
        path: '/photo/:photoid',
        element: <Photo />
    }
];

export default AppRoutes;
