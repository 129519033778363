export default function TagList({tags, onTagToggle}) {

    let tagLabels = [];
    tagLabels.push(<li key="all-works-tag"><span className="tag" onClick={() => onTagToggle(null)}>All Works</span></li>);

    if (tags != null) {
        tags.forEach((tag) => {
            tagLabels.push(<li key={tag.name}>
                <span className={tag.selected ? "tag selected" : "tag"} onClick={() => onTagToggle(tag)}>
                    {tag.displayName ? tag.displayName : tag.name}
                </span>
            </li>);
        });
    }

    return (
        <div id="tagFilter" key="tag-filter">
            <ul key="tag-list">
                {tagLabels}
            </ul>
        </div>);
};
