import React, { useState, useEffect, useCallback } from 'react';
import { Container } from 'reactstrap';
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import TagList from './TagList';
// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Share from "yet-another-react-lightbox/plugins/share";
import InfiniteScroll from 'react-infinite-scroll-component';
import GridLoader from 'react-spinners/GridLoader'
import { useNavigate, useParams } from "react-router-dom";
import URI from 'urijs';
import './Gallery.css';

function Gallery() {
    const [photos, setPhotos] = useState([]);
    const [allTags, setAllTags] = useState([]);
    const [activeTags, setActiveTags] = useState([]);
    const [index, setIndex] = useState(-1);
    const [nextIndexToRequest, setNextIndexToRequest] = useState(0);
    const [moreAvailable, setMoreAvailable] = useState(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    let { tagsCsv } = useParams();
    let navigate = useNavigate();

    const defaultPageSize = 25;
    const tags = tagsCsv ? tagsCsv.split(",") : [];

    const fetchPhotos = async (startIndex = 0, pageSize = 25, tags = []) => {
        setNextIndexToRequest(startIndex + pageSize);

        if (pageSize === 0)
            return [];

        let url = new URI('api/photo/getpaged')
            .addSearch('startIndex', startIndex)
            .addSearch('pageSize', pageSize)
            .addSearch('tags', tags)
            .toString();

        const response = await fetch(url);
        const data = await response.json();
        const photos = data.map(p => ({
            ...p,
            src: p.thumbnailUrl,            //used by both gallery and lightbox, unless renderer is replaced in any of those
            srcSet: [
                { src: p.thumbnailUrl, width: p.thumbnailWidth, height: p.thumbnailHeight },
                { src: p.url, width: p.width, height: p.height },
            ],
            share: `/photo/${p.id}`     //direct link used by lightbox share plugin
        }));

        setMoreAvailable(data.length === pageSize);

        return photos;
    };

    const fetchTags = useCallback(async () => {
        const response = await fetch('api/tag');
        const fetchedTags = await response.json();

        return fetchedTags;
    }, []);

    useEffect(() => {
        setActiveTags(tags);
        setError(null);

        const initialize = async () => {
            try {
                const [fetchedPhotos, fetchedTags] = await Promise.all([fetchPhotos(0, defaultPageSize, tags), fetchTags()]);

                setPhotos(fetchedPhotos);
                setAllTags(fetchedTags);
            }
            catch (ex) {
                setError(ex);
                setMoreAvailable(false);
            }
            finally {
                setLoading(false);

            }
        }
        initialize();

    }, []);

    const loadMore = async () => {
        let morePhotos = await fetchPhotos(nextIndexToRequest, defaultPageSize, activeTags);

        setPhotos([...photos, ...morePhotos]);
    };

    const toggleTag = async (tag) => {
        let nextTags = [];

        if (tag != null) {
            nextTags = [tag.name];
            tag.selected = true;
        }

        const nextTagsCsv = nextTags.join(",");
        setActiveTags(nextTags);
        navigate(`/gallery/${nextTagsCsv}`, { replace: true });

        const updatedPhotos = await fetchPhotos(0, defaultPageSize, nextTags);

        setPhotos(updatedPhotos);
    };


    const onPhotoClick = (event) => {
        setIndex(event.index);
    };

    let resetPhotoIndex = () => setIndex(-1);

    let renderPhoto = ({ photo, wrapperStyle, renderDefaultPhoto }) => (
        <div className="photo-wrapper" style={{ position: "relative", ...wrapperStyle }}>
            <div className="photo-frame" data-tags={photo.tags.join(',')}>
                {renderDefaultPhoto({ wrapped: true })}
                <div className="photo-title">
                    {photo.location}, {photo.countryCode}
                </div>
            </div>
        </div>
    );

    let tagFilter = <TagList key="tag-list" tags={allTags} onTagToggle={toggleTag} />;

    let columnsNumber = (containerWidth) => {
        if (containerWidth < 800) return 1;
        if (containerWidth < 1200) return 2;
        return 3;
    };

    let mainContent = <PhotoAlbum id="image-grid" photos={photos} layout="masonry" spacing="2" columns={columnsNumber} key="photo-list"
        onClick={onPhotoClick} renderPhoto={renderPhoto}
    />;

    let lightbox = <Lightbox slides={photos} open={index >= 0} index={index} close={resetPhotoIndex}
        animation={{ fade: 300, swipe: 0 }} render={{ iconShare: () => <img src="icons/share.svg"/> }}
        plugins={[Fullscreen, Slideshow, Share]} key="gallery-lightbox"
    />;

    let loader = <p className="gallery-loader"><GridLoader color="#6c757d" /></p>;
    let errorAlert = <p className="gallery-noresults">Could not load photos. Sorry!</p>
    let nothingToShow = <p className="gallery-noresults"><em>Nothing to show...</em></p>;
    let allLoaded = <p className="gallery-loading-complete">This is it! Change tag to discover more photos!</p>

    if (photos.length === 0 && !loading)
        mainContent = nothingToShow;

    if (error != null)
        mainContent = errorAlert;

    return (
        <Container fluid id="gallery-container" className="px-1 mx-0">
            {tagFilter}
            <InfiniteScroll
                dataLength={photos.length}
                next={loadMore}
                hasMore={moreAvailable}
                loader={loader}
                endMessage={photos.length > 0 ? allLoaded : ''}
            >
                {mainContent}
            </InfiniteScroll>
            {lightbox}
        </Container>
    );
}

export default Gallery;