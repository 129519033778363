import React, { Component } from 'react';
import { Container } from 'reactstrap';
import './Author.css';


export default class Author extends Component {
    static displayName = Author.name;

  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {
      return (
          <Container className="author">
                <div className="row">
                      <div className="col col-sm-12 col-lg-6 mb-5">
                        <h1>Author</h1>
                        <img className="profile-picture" id="profile-picture" src="/img/avatar.jpg" alt="" />
                    </div>
           
                </div>
                <div className="row">
                    <div className="col">
                         <p id="bio"><strong>Mikolaj Kocot,</strong><br />
                            born in Poland, Krakow, based in Geneva, Switzerland.<br />
                            Outdoor fan, sports freak, youtuber and photographer.<br />
                            Enjoy browsing through the galleries of my trips and everyday's life.<br />
                        </p>
                    </div>
                </div>
          </Container>
    );
  }
}
