import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import { Footer } from './Footer';

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
      return (
          <div className="page-layout d-flex flex-column min-vh-100">
              <NavMenu />
              <main className="flex-grow-1">
                {this.props.children}
            </main>
            <Footer />
        </div>
    );
  }
}
