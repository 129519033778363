import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header>
              
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow main-menu" light>
                        <NavbarBrand className="profile-picture" tag={Link} to="/">
                            <img src="/img/avatar.jpg" alt="" />
                            <div className="slogan">Kocot - the world through my eyes!</div>
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow menu-items">
                                <NavItem>
                                    <NavLink tag={Link} className="menu-item" to="/">GALLERY</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="menu-item" to="/author">AUTHOR</NavLink>
                                </NavItem>
                            </ul>
                        </Collapse>
                </Navbar>
            </header >
        );
    }
}
