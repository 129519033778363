import React from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from 'reactstrap';

const InternalError = () => {

    return (
        <Container fluid className="not-found">
            <Row className="justify-content-center">
                <Col className="col-auto">
                    <Link to="/"><span className="icon">☹</span>
                    </Link>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Col className="col-auto">
                    <h1 className="text">something went wrong</h1>
                </Col>
            </Row>
        </Container>
    );
};

export default InternalError;