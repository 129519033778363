import React, { Component } from 'react';

export class Footer extends Component {
  static displayName = Footer.name;

  render() {
    return (
        <footer className="container-fluid">
            <div className="row">
                <div className="col copyrights">
                    <p className="copyrights-long">Copyright &#169; 2023 Mikolaj Kocot. All Rights Reserved.</p>
                    <p className="copyrights-short">Mikolaj Kocot &#169; 2023</p>
                </div>
                <div className="col social-links text-end">
                    <a href="https://www.instagram.com/mikocot" className="social-link" target="_blank"><img src="icons/instagram.svg" width="20" height="20" /><span>mikocot</span></a>
                    <a href="https://www.facebook.com/teamkocot" className="social-link" target="_blank"><img src="icons/facebook.svg" width="20" height="20" /><span>teamkocot</span></a>
                    <a href="https://www.youtube.com/@kocot." className="social-link" target="_blank"><img src="icons/youtube.svg" width="20" height="20" /><span>kocot.</span></a>
                </div>
            </div>
        </footer>
    );
  }
}
